import React from "react";

import Heading from "components/Heading";

import "./_virtual-tour.scss";

const VirtualTour = () => {
  return (
    <div className="virtual-tour">
      <Heading headingLevel="h1" isCentered={true}>
        Virtualus turas
      </Heading>
      <iframe
        width="853"
        height="480"
        src="https://my.matterport.com/show/?m=L5SqiDtwV3G&brand=0"
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
      ></iframe>
    </div>
  );
};

export default VirtualTour;
