import React, { useEffect, useRef } from "react";
import { gsap, Power2 } from "gsap";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import StyledLink from "components/StyledLink";
import Heading from "components/Heading";
import { textTransition } from "components/Animations";

import "./_text-block.scss";

const TextBlock = ({
  title,
  text,
  buttonUrl,
  buttonText,
  imageData,
  imageAlt,
}) => {
  const trigger = useRef(null);
  const heading = useRef(null);
  const textRef = useRef(null);
  const button = useRef(null);
  const imageOverlay = useRef(null);

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          imageOverlay.current &&
            tl.to(
              imageOverlay.current,
              1.4,
              {
                width: "0%",
                ease: Power2.easeInOut,
              },
              "-=1"
            );

          tl.fromTo(heading.current, duration, from, to)
            .fromTo(textRef.current, duration, from, to, "-=0.7")
            .fromTo(button.current, duration, from, to, "-=0.7");

          observer.unobserve(trigger.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (trigger.current) {
      observer.observe(trigger.current);
    }
  }, []);

  return (
    <div ref={trigger} className="text-block">
      {imageData && (
        <div className="text-block__wrapper">
          <div className="text-block__inner-wrapper">
            <Img fluid={imageData} alt={imageAlt} />
            <div ref={imageOverlay} className="text-block__image-overlay"></div>
          </div>
        </div>
      )}
      <div ref={heading} className="text-block__title">
        <Heading headingLevel="h2" headingStyle="h3">
          {title}
        </Heading>
      </div>
      <p ref={textRef} className="text-block__text">
        {text}
      </p>
      <div ref={button}>
        <StyledLink text={buttonText} url={buttonUrl} />
      </div>
    </div>
  );
};

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  imageData: PropTypes.object,
  imageAlt: PropTypes.string,
};

export default TextBlock;
