import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PropTypes from "prop-types";

import "./_styled-link.scss";

const StyledLink = ({ url, text, isWhite }) => {
  return (
    <AniLink
      cover
      duration={1.5}
      bg="#d9e3e0"
      className={isWhite ? "styled-link styled-link--white" : "styled-link"}
      to={url}
    >
      {text}
      <span className="styled-link__arrow">→</span>
    </AniLink>
  );
};

StyledLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isWhite: PropTypes.bool,
};

export default StyledLink;
