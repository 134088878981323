import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { navigation, sodyba } from "src/data";
import Header from "components/Header";
import About from "components/About";
import Main from "components/Main";
import Footer from "components/Footer";
import TextBlock from "components/TextBlock";
import Row from "components/Row";
import Button from "components/Button";
import FloatGallery from "components/FloatGallery";
import VirtualTour from "components/VirtualTour";

const Sodyba = () => {
  const data = useStaticQuery(graphql`
    query SodybaImages {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      uniq1: file(relativePath: { eq: "modernus-kambariai.jpg" }) {
        ...fluidImage
      }
      uniq2: file(relativePath: { eq: "sodyba-pramogos.jpg" }) {
        ...fluidImage
      }
      ex1: file(relativePath: { eq: "galerija/kudrenai(37).jpg" }) {
        ...fluidImage
      }
      ex2: file(relativePath: { eq: "galerija/kudrenai(20).jpg" }) {
        ...fluidImage
      }
      ex3: file(relativePath: { eq: "galerija/kudrenai(30).jpg" }) {
        ...fluidImage
      }
      ex4: file(relativePath: { eq: "galerija/kudrenai(40).jpg" }) {
        ...fluidImage
      }
      ex5: file(relativePath: { eq: "sodyba5.jpg" }) {
        ...fluidImage
      }
    }
  `);

  return (
    <>
      <Header
        items={navigation}
        logo={data.logo.childImageSharp.fluid}
        logoAlt="Kudrėnų sodybos Logotipas"
        navPosition="right"
      />
      <Main>
        <About
          heading={sodyba.heading}
          firstParagraph={sodyba.firstParagraph}
          secondParagraph={sodyba.secondParagraph}
          thirdParagraph={sodyba.thirdParagraph}
          fourthParagraph={sodyba.fourthParagraph}
          buttonText={sodyba.buttonText}
          buttonUrl={sodyba.buttonUrl}
        />
        <VirtualTour />
        <Row>
          <TextBlock
            title="Modernūs kambariai jums"
            text="Jūsų patogumui, siūlome apsigyventi šiuolaikiškai įrengtuose kambariuose. Galima užsisakyti tiek vieną kambarį, tiek visą sodyba. Sodyboje galima poilsiauti ir vasarą, ir žiemą."
            buttonText="Mūsų kambariai"
            buttonUrl="/kambariai"
            imageData={data.uniq1.childImageSharp.fluid}
            imageAlt="Kudrenu sodyba su nakvyne"
          />
          <TextBlock
            title="Pramogos visai šeimai"
            text="Medžiu kvepianti sauna, tiršto garo turkiška pirtis ir gaivinantis baseino vanduo visais metų laikais. O galbūt norite pasidžiaugti ramybe ir tiesiog vakarą praleisti ant supynių prie Nevežio upės?"
            buttonText="Pramogos"
            buttonUrl="/pramogos"
            imageData={data.uniq2.childImageSharp.fluid}
            imageAlt="Kaimo turizmo sodyba su pramogomis"
          />
        </Row>
        <FloatGallery
          imageData={[
            data.ex1.childImageSharp.fluid,
            data.ex2.childImageSharp.fluid,
            data.ex3.childImageSharp.fluid,
            data.ex4.childImageSharp.fluid,
            data.ex5.childImageSharp.fluid,
          ]}
          imageAlt={[
            "Sodyba kudrenai",
            "Sodyba kudrenai kaunas",
            "sodyba Kaune",
          ]}
        />
        <Button url="/kontaktai">Rezervuoti vizitą</Button>
      </Main>
      <Footer />
    </>
  );
};

export default Sodyba;
